import { Component,OnDestroy,OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginDataService } from '../demo/service/loginData.service';
import {LogonService} from '../demo/service/logon.service';
import {ExternalAccessDetailsService} from '../../app/demo/service/externalaccessdetails.service'
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-draftresponse',
  styleUrls: ['./app.draftresponse.component.scss'],
  templateUrl: './app.draftresponse.component.html',
  providers: [MessageService]
})
export class AppDraftResponseComponent implements OnInit , OnDestroy {
  servicenameFlag:false;
  servicenameparam:any;
  serviceId:any;
  visitId:any;
  password:any;
  submitted:boolean=false;
  constructor(private route: ActivatedRoute,private router: Router,private loginService:LoginDataService
    , private externalaccessservice : ExternalAccessDetailsService, private messageService: MessageService
    ,private logonservice: LogonService) { 
   
  }
    ngOnDestroy(): void {
        localStorage.removeItem('isextlanding');
    }
    ngOnInit(): void {

        localStorage.setItem('isextlanding','true');
        this.route.queryParams.subscribe(params => {
            this.servicenameparam = params['servicename'];
           
        });
        
    }
    validateservRes()
    {
        this.submitted=true;
        console.log("calling this method---")
        if(this.serviceId==undefined || this.serviceId==null)
        {
            this.serviceId='';
        }
        if(this.visitId==undefined || this.visitId==null)
        {
            this.visitId='';
        }
        if(this.password==undefined || this.password==null)
        {
            this.password='';
        }

        localStorage.setItem("serviceId",this.serviceId);
        localStorage.setItem("visitId",this.visitId);
        localStorage.setItem("password",this.password);
        
           //code to validate the service id, password and asr id
        if(this.serviceId!=null && this.serviceId.trim()!='' 
        && this.visitId!=null && this.visitId.trim()!='' 
        && this.password!=null && this.password.trim()!='')
    {
        
        this.externalaccessservice.getServiceDetails(this.serviceId,this.visitId,this.password).subscribe(data=>{
          
            if(data!=undefined && data!=null)
            { 
                //check if the url is invalid
                if(data.fbCompleteFlag!=null && data.fbCompleteFlag=='Y')
                {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Access unavailable - feedback form already submitted' });
                    this.submitted=false;
                    return;
                }

                let today = new Date();
                var validtilldate = new Date(data.fbValidEndDate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"))
                validtilldate.setHours(0, 0, 0, 0);
                today.setHours(0, 0, 0, 0);

                if (today > validtilldate) {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Your access has expired. Please contact the Network Coordination team for assistance.' });
                    this.submitted=false;
                    return;
                }

                localStorage.setItem('firstName',this.servicenameparam);
                localStorage.setItem('surname','');
             
                localStorage.setItem("serviceId",this.serviceId);
                localStorage.setItem("visitId",this.visitId);
                localStorage.setItem("password",this.password);

                this.router.navigate(['main/externalaccess/responsetodraft/main']);

            }  else{
                
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please check your ASR Number, Service ID and Password.' });
                this.submitted=false;
            }
        })
                
                //create empty initial table records
              /*  this.logonservice.create(this.serviceId,this.visitId).subscribe(resp=>{
                        console.log("initial data tables created");
                        this.router.navigate(['main/externalaccess/responsetodraft/main']);
                },err=>{
                   console.log(err); 
                })*/

            }
            else{
                
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please check your ASR Number, Service ID and Password.' });
                this.submitted=false;
            }
      /*  },err=>{
            console.log(err);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Internal error: '+err });
  
        })*/
    }
        
   // }
}
